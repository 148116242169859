@use "./assets/scss/reset.scss";
@use 'bootstrap/scss/bootstrap.scss';
@use 'ngx-toastr/toastr';
@use "./assets/scss/variables.scss";
@use "./assets/scss/helpers.scss";
@use "./assets/scss/animations.scss";
@use "./assets/scss/tooltips.scss";
@use "./assets/scss/ie.scss";
@use "./assets/scss/icons.scss";
@use "./assets/scss/im-webfont.scss";
@use "./assets/scss/fonts.scss";


// general
::placeholder {
    color: #d8d8d8;
    opacity: 1;
}
ul{
    padding-left: 0;
}
.theme--dark {
    ::placeholder {
        // color: var(--shade-7);
    }
}
.grecaptcha-badge { 
    opacity: 0;
    pointer-events: none;
    transition: right 0.3s ease 0s, opacity .2s ease 0s !important;
}
.show-captcha .grecaptcha-badge { 
    opacity: 1;
    pointer-events: all;
}
body {
    font-size: 18px;
    line-height: 1;
    background: #FAFAFA;
}
.background{
    background: var(--shade-0);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.panel{
    background: var(--white);
    padding: 20px;
    border: 1px solid var(--border-gray);
}
body * {
    font-family: proxima-nova, sans-serif;
}
h1, h2, h3, h4{
    margin: 0;
    color: var(--text-color);
}
h1{
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    font-weight: 600;
    color: var(--text-color);
    .light{
        font-weight: 300;
        font-size: 38px;
    }
}
h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 25px;
    color: var(--gray);
    font-size: 26px;
    margin: 0;
    &>span{
        font-weight: 600;
        font-size: 25px;
        color: var(--gray);
        font-size: 26px;
    }
    &.light{
        font-weight: 300;
        font-size: 26px;
    }
    &.black{
        color: var(--text-color);
    }
}
h3{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}
h4{
    font-family: 'Poppins', sans-serif;
    font-size: variables.$fontsize-l;
}
.poppins{
    font-family: 'Poppins', sans-serif;
}
// .uni_neuelight{
//     font-family: "uni_neuelight", sans-serif;
// }
// .uni_neueregular{
//     font-family: "uni_neueregular", sans-serif;
// }
// .uni_neuebold{
//     font-family: "uni_neuebold", sans-serif;
// }

header {
    &.is-admin{
        background-color: var(--secondary) !important;
        nav>ul>li>a, .title, .sub{
            color: #fff !important;
            &.active{
                text-decoration: underline !important;
            }
        }
        .avatar{
            background-color: transparent !important;
            border-color: #fff !important;
            *{
                color: #fff !important;
            }
        }
    }
}

.search-bar-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 8px;
    .reset-search{
        margin-left: 24px;
        white-space: nowrap;
        font-size: 14px;
    }
    .search-bar{
        width: 100%;
        // max-width: 700px;
        
        input{
            display: block;
            width: 100%;
            padding: 10px 19px;
            border: 1px solid var(--border-gray);
            border-right: none;
            border-radius: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            outline: none;
            background-color: var(--white);
            color: var(--black);
            min-height: 54px;
            font-size: 20px;
        }
        app-field-select{
            display: inline-flex;
            flex-direction: column;
            &>div{
                flex-grow: 1;
                display: inline-flex;
                flex-direction: column;
                select{
                    flex-grow: 1;
                    border: none !important;
                    border-radius: 0 !important;
                    width: 180px !important;
                    border-top: 1px solid var(--border-gray) !important;
                    border-bottom: 1px solid var(--border-gray) !important;
                    border-left: 1px solid var(--border-gray) !important;
                    padding-left: 20px;
                    padding-right: 40px;
                    background: #ffffff url(/assets/img/icons/icon-dropdown.svg) right 16px center no-repeat;
                    background-size: 16px;
                }
            }
        }
        .button{
            border-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            height: auto !important;
            padding: 0 !important;
            width: 64px;
            background: var(--secondary) !important;
            flex-shrink: 0;
            .im-icon{
                margin: 0;
                font-size: 21px;
            }
        }
    }
}


.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
.subtitle-m{
    color: var(--shade-5);
    font-size: variables.$fontsize-l;
}
.sep{
    width: 56px;
    height: 2px;
    display: block;
    background-color: var(--primary-color);
}
.sep-h{
    width: 100%;
    height: 1px;
    display: block;
    background: var(--border-gray);
}
main{
    min-height: 100vh;
    color: var(--text-color);
    // background: var(--shade-0);
    position: relative;
    // padding-top: variables.$headerHeight;
}
.container {
    max-width: 1680px !important;
    max-width: 1920px !important;
    max-width: 3840px !important;
    max-width: 1500px !important;
    padding: 0;
    position: relative;
    padding-left: variables.$spacing-s;
    padding-right: variables.$spacing-s;
}
.page{
    padding-top: 60px !important;
    padding-bottom: 50px !important;
    max-width: 1300px !important;
}

body div .language-switch{
    padding-right: 30px;
    span{
        color: var(--gray);
        font-weight: 300;
    }
    select{
        background: transparent !important;
        position: absolute;
        display: inline-block;
        width: auto;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        padding: 1px 4px;
        background: transparent url(/assets/img/icons/icon-dropdown-darker.svg) right 10px bottom 2px no-repeat !important;
        background-size: 13px 13px;
        // padding-right: 35px;
        color: var(--gray);
        min-height: 0;
    }
    .arrow-fit{
        color: var(--gray);
        position: relative !important;
    }
}

// helpers
.saving{
    transition: .1s ease;
    opacity: .6;
    pointer-events: none;
}
.no-pointer{
    pointer-events: none;
}
.opacity-hide{
    transition: .2s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.lh-1{
    line-height: 1;
}
.h-100{
    height: 100%;
}
.uppercase{
    text-transform: uppercase;
}

// toasts
#toast-container{
    max-width: 100%;
    padding-left: 24px;
    .ngx-toastr{
        max-width: 100%;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #ffffff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid var(--green);
    }
    .toast-warning{
        border-left: 5px solid var(--orange);
    }
    .toast-error{
        border-left: 5px solid var(--red);
    }
    .toast-info{
        border-left: 5px solid var(--secondary);
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: variables.$fontsize-m;
        color: var(--text-color);
        font-family: "uni_neueregular", sans-serif;
    }
    .toast-message{
        font-size: variables.$fontsize-xs;
        color: #555555;
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}
.app-alert{
    background-color: #569ff9;
    color: white;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    padding-top: 7px;
    padding-bottom: 7px;
}
// display
.display-label{
    color: var(--text-color);
    // color: #909090;
    // color: var(--gray);
    font-size: 20px;
    display: inline-block;
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    white-space: nowrap;
}
// .display-row{
    // margin-bottom: variables.$spacing-m;
    .display-value{
        // color: var(--black);
        font-size: 18px;
        margin-bottom: 12px;
    }
    // &:last-of-type{
    //     margin-bottom: 0;
    // }
    .display-content{
        margin-bottom: variables.$spacing-m;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
// }

.form-row:last-of-type, .display-row:last-of-type{
    .display-value{
        margin-bottom: 0 !important;
    }
}

// buttons
.button{
    transition: 0.2s ease;
    color: var(--white-fixed);
    box-shadow: none !important;
    outline: none !important;
    padding: 13px 22px;
    background: var(--primary-color);
    border-radius: 6px;
    border: none;
    height: 0%;
    display: flex;
    align-items: center;
    font-size: 20px;
    &:hover{
        background: var(--primary-color);
        filter: brightness(1.1);
    }
    &.default{
        background: var(--secondary);
        // background: var(--primary-color);
        &:hover{
            background: var(--secondary);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
        // ivm
        .theme--dark & {
            background-color: #4FB0D4;
        }
    }
    &.default--light{
        background: var(--white);
        color: var(--secondary);
        border: 2px solid var(--secondary);
        padding: 11px 20px;
        &:hover{
            background: var(--secondary);
            color: var(--white);
            filter: none;
        }
        .theme--dark & {
            // background-color: #4FB0D4;
        }
    }
    &.green{
        background: var(--primary-color);
        color: white !important;
        font-weight: 500;
        &:hover{
            background: var(--primary-color);
            filter: brightness(1.1);
        }
    }
    &.green--light{
        background: var(--white);
        color: var(--primary-color);
        font-weight: 500;
        border: 2px solid var(--primary-color);
        padding: 11px 20px;
        &:hover{
            background: var(--primary-color);
            color: var(--white);
            filter: none;
        }
        .theme--dark & {
            // background-color: #4FB0D4;
        }
        &.button--spacious{
            padding: 15px 42px !important;
        }
    }
    &.button--spacious{
        padding: 17px 44px !important;
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
        // background: var(--shade-2);
    }
    .im-icon{
        font-size: variables.$fontsize-xxs;
        margin-right: 7px;
    }
    &.cancel{
        padding: 8px 11px;
        background: var(--popup-background);
        color: var(--shade-7);
        font-size: variables.$fontsize-s;
        border: 1px solid var(--shade-7);
    }
    &.confirm{
        padding: 8px 11px;
        background: var(--red-light);
        color: var(--white-fixed);
        font-size: variables.$fontsize-s;
        border: 1px solid var(--red-light);
    }
}
.button-link{
    color: var(--link-blue);
    // height: 0%;
    display: flex;
    align-items: center;
    text-decoration: underline;
    &:hover span{
        text-decoration: underline;
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
}
button .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 12px;
    text-decoration: none !important;
    transition: 0s;
}
button .im-icon-plus{
    font-size: 14px !important;
}
.export-btn .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
}
.export-btn {
    text-decoration: none !important;
}
.export-btn:hover span {
    text-decoration: underline !important;
}
.button-row-sep{
    background: var(--shade-3);
    width: 1px;
    align-self: stretch;
}
.hightlight-hover .avatar{
    // transition: .2s ease;
    // &:hover{
    //     color: white;
    //     background: var(--primary-color);
    // }
}

// modals
.modal{
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.modal--1150 .modal-dialog{
        max-width: 1150px;
    }
    &.detail-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1920px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
            .theme--dark & {
                background-color: var(--background-color);
            }
        }
    }
    &.login-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(700px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        box-shadow: 0px 0px 23px 0px rgb(0 0 0 / 10%);
        .modal-content{
            height: 100%;
            padding-bottom: 60px;
            padding-top: 55px;
            padding-left: 64px;
            padding-right: 64px;
        }
    }
    &.adduser-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(800px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        box-shadow: 0px 0px 23px 0px rgb(0 0 0 / 10%);
        .modal-content{
            height: 100%;
            padding-bottom: 60px;
            padding-top: 55px;
            padding-left: 64px;
            padding-right: 64px;
        }
    }
    .modal-dialog{
        height: auto !important;
        // height: calc(100% - #{variables.$spacing-xxl}) !important;
        overflow: auto !important;
        border-radius: 5px !important;
        margin-top: 60px;
        .modal-content{
            // padding: 25px 30px;
            border: none;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            min-height: 400px;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

.stamp{
    border: 1px solid var(--border-gray);
    display: inline-block;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 16px;
    &.stamp--orange{
        border-color: var(--orange);
        color: var(--orange);
    }
    &.stamp--blue{
        border-color: var(--blue);
        color: var(--blue);
    }
}

.categories{
    .field-checkbox-option{
        margin-bottom: 14px;
        margin-bottom: 5px;
    }
}

.success-state{
    padding-left: 190px;
    padding-top: 40px;
    padding-bottom: 70px;
    .title{
        font-size: 36px;
        color: var(--text-color);
        font-weight: 600;
    }
    .subtitle{
        font-size: 35px;
        color: var(--text-color);
        font-weight: 300;
        line-height: .8;
    }
    .descr{
        max-width: 780px;
    }
}
.failure-state{
    padding-left: 190px;
    padding-top: 40px;
    padding-bottom: 70px;
    .title{
        font-size: 36px;
        color: var(--text-color);
        font-weight: 600;
    }
    .subtitle{
        font-size: 35px;
        color: var(--text-color);
        font-weight: 300;
        line-height: .8;
    }
    .descr{
        max-width: 780px;
    }
}

// forms
// .row-form{
//     margin-bottom: 28px;
//     &:last-of-type{
//         margin-bottom: 0;
//         .display-value{
//             margin-bottom: 0;
//         }
//     }
// }
.row-form{
    margin-bottom: 0;
    &>div{
        margin-bottom: 24px;
    }
    &:last-of-type{
        &>div{
            margin-bottom: 0;
        }
    }
}

// fields
.label--lone {
    color: var(--text-color);
    font-size: 20px;
    display: inline-block;
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    white-space: nowrap;
}
.label--sep{
    margin-bottom: 12px;
    // margin-left: 14px;
    margin-right: 14px;
}
.error-field-display--lone {
    display: block;
    font-size: 16px;
    color: var(--red);
    line-height: normal;
    padding-top: 2px;
}
.field {
    .label{
        color: var(--text-color);
        font-size: 20px;
        display: inline-block;
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
        white-space: nowrap;
        span{
            font-weight: 300;
            color: var(--shade-6);
            margin-left: 3px;
            font-size: 16px;
        }
    }
    .after-string{
        margin-left: 12px;
        font-size: 16px;
        white-space: nowrap;
        color: var(--gray);
    }
    .error-field{
        border: 1px solid var(--red) !important;
    }
    .invalid-field{
        background-color: var(--red-background);
    }
    .error-field-display {
        display: block;
        font-size: 16px;
        color: var(--red);
        line-height: normal;
        padding-top: 2px;
    }
}

// datepicker
.myDpSelector{
    bottom: calc(100% + 4px)!important;
    top: auto !important;
    left: -1px !important;
    border: 1px solid var(--shade-2) !important;
    box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
    padding: 6px 7px !important;
    .theme--dark & {
        border-color: #6f747b !important;
    }
}
.myDpSelectorArrow, .myDpMonthYearSelBar, .myDpCalTable, .myDpMonthTable, .myDpYearTable, .myDpDaycell {
    background: var(--popup-background) !important;
}
.myDpWeekDayTitle{
    color: var(--text-color) !important;
}
.myDpTableSingleDay:hover, .myDpTableSingleMonth:hover, .myDpTableSingleYear:hover {
    background: var(--shade-2) !important;
    .theme--dark & {
        background: #656c79 !important;
    }
}
.myDpMonthYearText button{
    color: var(--text-color) !important;
    pointer-events: none;
}
.myDpDayValue{
    color: var(--text-color) !important;
    transform: translatey(1px);
    display: block;
}
.myDpNextMonth .myDpDayValue, .myDpPrevMonth .myDpDayValue{
    color: var(--shade-6) !important;
}
body div .myDpSelectedDay, body div .myDpSelectedDay .myDpSelectedMonth, body div .myDpSelectedDay .myDpSelectedYear {
    border: none !important;
    background: var(--secondary) !important;
    border-radius: 3px !important;
    &:hover{
        background: var(--secondary) !important;
    }
    .myDpDayValue{
        color: white !important;
    }
}
.ng-mydp table td, .ng-mydp table th {
    border-radius: 3px;
}
.myDpMarkCurrDay, .myDpMarkCurrMonth, .myDpMarkCurrYear{
    border: none !important;
}
.myDpMarkCurrDay::after{
    content: "";
    width: 100%;
    height: calc(100% + 16px);
    background-color: var(--shade-2);
    position: absolute;
    left: 0;
    z-index: -1;
    right: 0;
    top: -9px;
    bottom: 0;
    border-radius: 3px;
    .theme--dark & {
        background-color: #656c79 !important;
        border-color: #6f747b;
    }
}
.myDpSelectedDay .myDpMarkCurrDay::after{
    background: transparent;
}
.myDpSelectorArrow:before{
    border-color: transparent !important;
    border-top-color: var(--popup-background) !important;
    border-width: 8px !important;
    margin-left: -8px !important;
    top: 100% !important;
    z-index: 1 !important;
}
.myDpSelectorArrow:after{
    border-color: rgba(250,250,250,0) !important;
    border-top-color: var(--shade-2) !important;
    border-width: 9px !important;
    margin-left: -9px !important;
    top: 100% !important;
    .theme--dark & {
        border-top-color: #6f747b !important;
    }
}
.ng-mydp *{
    font-family: proxima-nova, sans-serif !important;
    font-size: variables.$fontsize-s !important;
    // color: var(--text-color) !important;
}
.myDpWeekDayTitle{
    font-size: variables.$fontsize-s !important;
    font-weight: 600 !important;
}
.myDpIconLeftArrow {
    font-family: "imengine" !important;
}
.myDpIconRightArrow {
    font-family: "imengine" !important;
}
.myDpIconLeftArrow:before {
    content: "\ea03" !important;
}
.myDpIconRightArrow:before {
    content: "\ea04" !important;
}
.myDpIcon{
    font-size: 13px !important;
    color: var(--text-color) !important;
}
.myDpMonthYearText *{
    font-weight: 600 !important;
}
.myDpMonthYearSelBar{
    margin-bottom: 7px !important;
}
.myDpNoFooter {
    height: calc(100% - 35px) !important;
}
.myDpDaycell:focus, .myDpMonthcell:focus, .myDpYearcell:focus{
    box-shadow: inset 0 0 0 1px var(--shade-2) !important;
    .theme--dark & {
        box-shadow: inset 0 0 0 1px #656c79 !important;
    }
}

// profile popover
.profile-popover{
    min-width: 260px;
    // border-top: 2px solid var(--primary-color);
    // &::after{
    //     content: '';
    //     border-style: solid;
    //     border-width: 0 3px 3px 3px;
    //     border-color: transparent transparent var(--primary-color) transparent;
    //     position: absolute;
    //     bottom: 100%;
    //     right: 13px;
    // }
    .head{
        background: var(--popup-background-alt);
    }
    .options{
        ul{
            li{
                border: none;
                background: none !important;
                padding: 0;
                &.logout{
                    a{
                        color: var(--gray) !important;
                    }
                }
                &:last-of-type{
                    a div{
                        border-bottom: none;
                    }
                }
                a{
                    &:hover{
                        background: rgb(251, 251, 251);
                    }
                    div{
                        border-bottom: 1px solid #eaeaea;
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                    width: 100%;
                    transition: .1s ease;
                    font-size: 18px;
                    line-height: 24px;
                    color: var(--text-color);
                    padding-left: 18px;
                    padding-right: 18px;
                    text-decoration: none;
                    * {
                        font-size: 18px;
                    }
                    span{
                        min-width: 27px;
                        i{
                            font-size: 18px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

// side panel
.side-panel{
    padding: variables.$spacing-m variables.$spacing-l variables.$spacing-s variables.$spacing-l;
    width: variables.$sidePanelWidth;
    background: var(--shade-2);
    border-right: 1px solid var(--shade-3);
    height: calc(100vh - #{variables.$headerHeight});
    overflow-y: auto;
    position: fixed;
    top: variables.$headerHeight;
    z-index: 2;
    .side-panel-footer{
        padding-top: variables.$spacing-s;
        font-size: variables.$fontsize-xxs;
        text-align: center;
        color: var(--shade-8);
        justify-self: end;
        width: 100%;
        .theme--dark & {
            color: var(--shade-3);
        }
        a{
            color: var(--secondary);
            text-decoration: underline;
            .theme--dark & {
                color: var(--white-fixed);
            }
        }
    }
}
.side-panel-main{
    margin-left: variables.$sidePanelWidth;
    max-width: calc(100vw - #{variables.$sidePanelWidth});
    overflow: hidden;
}
.panel-footer{
    background: #ebebeb;
    .theme--dark & {
        background: #3a3a3a;
    }
    padding-top: 28px;
    padding-bottom: 28px;
}

// bootstrap overwrites
.dropdown-menu{
    z-index: 999 !important;
    min-width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(144, 144, 144, 0.3) !important;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1;
    max-height: 306px;
    overflow-y: auto;
    background: var(--popup-background);
}
.dropdown-item{
    padding: 8px 10px;
    color: var(--text-color);
}
.dropdown-item.active, .dropdown-item:active{
    background: var(--primary-color);
}
.dropdown-item.active .typeahead-after, .dropdown-item:active .typeahead-after{
    color: #efefef;
}

// selection detail modal
.selection-detail-modal{
    background: var(--background-color);
    color: var(--text-color);
    .panel-head{
        border-color: #6c6e6d;
    }
    .edit, .cancel{
        .theme--dark & {
            color: var(--white-fixed);
        }
    }
    .modal-form-section{
        // transition: .1s ease;
        // &.edit-section{
        //     background-color: #f7fbfe;
        //     .theme--dark & {
        //         background-color: #282828;
        //         // background-color: var(--popup-background-alt);
        //     }
        // }
    }
    .start-form{
        transition: .1s ease;
        &.edit-section{
            background-color: #f7fbfe;
            .theme--dark & {
                background-color: #282828;
            }
        }
    }
}

.c{
    position: absolute;
    background: var(--green);
    top: 22px;
    left: 38px;
    width: 280px;
    height: 280px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
}
.c-red{
    background: var(--red)
}
.c-check{
    position: absolute;
    top: 36px;
    left: 42px;
    width: 70px;
    height: 70px;
}

.descr{
    font-size: 20px;
    line-height: 25px;
    color: var(--gray);
    font-weight: 300;
}
.or-line{
    margin-top: 27px;
    margin-bottom: 27px;
    max-width: 350px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    position: relative;
    .or{
        color: var(--text-color);
        font-size: 20px;
        text-transform: uppercase;
        padding: 16px;
        background: white;
        z-index: 1;
    }
    .line{
        height: 1px;
        background: var(--border-gray);
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        transform: translateY(-50%);
    }
}

// registration-history

.registration-history{
    .table {
        th, td{
            white-space: nowrap;
        }
        th{
            width: 25%;
        }
        tr{
            &.active td, &:hover td{
                background: rgb(247, 247, 247);
                &::after{
                    transition: .1s ease;
                }
                &:first-of-type::after{
                    opacity: 1;
                }
                &:last-of-type::after{
                    opacity: 1;
                }
            }
        }
    }
}

// checkmarks

/* Customize the label (the container) */
label.check {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: auto !important;
    display: inline-block;
    margin-bottom: 0;
}
/* Hide the browser's default checkbox */
label.check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: inline-block;
}
/* Create a custom checkbox */
.fake-checkbox {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 2px;
    border: 1px solid var(--gray);
    position: relative;
    transition: 0.1s ease;
    flex-shrink: 0;
    &::after{
        opacity: 0;
        content: "";
        position: absolute;
        left: 3px;
        top: 0px;
        display: block;
        transition: 0.1s ease;
        width: 6px;
        height: 8px;
        border: solid white;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg) translateY(0.5px) translateX(1px);
    }
}
/* When the checkbox is checked, add a blue background */
label.check input:checked ~ .fake-checkbox {
    border-color: var(--tertiary);
    background: var(--tertiary);
    border-color: var(--tertiary) !important;
    background: var(--tertiary) !important;
}
/* Show the fake-checkbox when checked */
label.check input:checked ~ .fake-checkbox:after {
    opacity: 1 !important;
}
label.check span.label{
    transform: translatey(-2px);
}

// index data table
.index{
    width: 100%;
    font-size: 20px;
    margin-bottom: 40px;
    // new
    th{
        font-size: 14px;
        color: var(--gray);
        font-weight: 600;
        padding-bottom: 9px;
        padding-top: 8px;
        border-bottom: 1px solid var(--border-gray);
        &.head--ticker{
            width: 1%;
            white-space: nowrap;
            min-width: 230px;
            
            text-overflow: ellipsis;
            max-width: 230px;
            overflow: hidden;
        }
    }
    td{
        // min-height: 100px;
        &.part{
            font-size: 26px;
            font-weight: 600;
            padding-top: 0 !important;
        }
        &.cat{
            color: var(--gray);
        }
        .input-wrap{
            max-width: 90px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
    .tr--first{
        td{
            padding-top: 11px;
        }
        td:first-of-type{
            border-bottom: 1px solid var(--border-gray);
            padding: 0;
        }
    }
    .tr--last{
        td{
            padding-bottom: 11px;
            border-bottom: 1px solid var(--border-gray);
        }
    }
    tr:last-of-type td{
        border: none !important;
    }

    &.index-form{
        tr.tr--first:last-of-type td:first-of-type{
            border: none !important;
        }
        tr.tr--first:not(:has(~ .tr--first)) td{
            border: none !important;
        }
    }

    // content
    .title{
        font-size: 26px;
        font-weight: 600;
    }
    .ticker{
        border-radius: 4px;
        padding: 4px 10px;
        padding-bottom: 3px;
        .eur-kg{
            font-size: 13px;
            margin-left: 4px;
            transform: translatey(2px);
        }
        i{
            transition: none !important;
            min-width: 15px;
            &.im-icon-plus{
                font-size: 9px;
                transform: translateY(-0.5px);
            }
            &.im-icon-minus{
                font-size: 8px;
                transform: translateY(-0.5px);
            }
            &.im-icon-equal{
                font-size: 8px;
                padding-left: 1px;
            }
        }
        &.up{
           background: #ecf7f2;
           color: var(--tertiary);
        }
        &.down{
            background: #fbebed;
            color: #da3e52;
        }
        &.equal{
            background: #f4f4f4;
            color: #959595;
        }
    }
    .pieces{
        font-size: 13px;
        margin-left: 4px;
        transform: translatey(2px);
        color: #b3b3b3;
    }
}
.new-index .field-text input{
    padding: 5px 9px;
    min-height: 36px;
}

// mobile index data table
.mobile-index{
    font-size: 20px;
    .mobile-index-section{
        background: white;
        border: 1px solid #ECECEC;
        margin-bottom: 12px;
        padding: 14px 16px;
        .mobile-index-part{
            font-size: 26px;
            font-weight: 600;
            margin-right: 10px;
            line-height: 26px;
        }
        .mobile-index-cat{
            color: var(--gray);
            font-weight: 300;
            line-height: 21px;
        }
        &>div:first-of-type{
            margin-bottom: 16px;
        }
        &>div:last-of-type{
            &>div:first-of-type{
                margin-right: 20px;
            }
        }
        .mobile-index-label{
            font-size: 14px;
            color: var(--gray);
            font-weight: 600;
            padding-bottom: 5px;

            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
        .mobile-index-value{
            min-width: 132px;
            .ticker{
                margin: 0 !important;
            }
            &.amount{
                transform: translatey(4px);
            }
        }
    }
}

// mobile index new-index
.new-index{
    .mobile-index{
        font-size: 16px !important;
        .field-text input{
            font-size: 16px !important;
            padding: 4px !important;;
            min-height: 30px !important;;
        }
        .pieces{
            font-size: 12px !important;
            font-weight: 300 !important;
            margin-left: 3px !important;
        }
    }
}

// text
.text{
    *{
        font-size: 16px;
        line-height: 22px;
        color: var(--black);
    }
    p{
        margin-bottom: 18px;
    }
    ul{
        margin-top: -14px;
        li{
            margin-bottom: 16px;
            position: relative;
            padding-left: 18px;
            &::after{
                content: "\2022";
                color: var(--gray);
                position: absolute;
                left: 5px;
                top: -1px;
                font-size: 22px;    
            }
        }
    }
    a{
        color: var(--link-blue);
    }
    table{
        margin-bottom: 18px;
        margin-top: 4px;
        th, td{
            vertical-align: middle;
            padding-left: 12px !important;
            padding-right: 12px !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            vertical-align: top !important;
            position: relative;
            &:first-of-type{
                padding-left: 0 !important;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
        td{
            border-bottom: 1px solid var(--shade-1-fixed);
        }
        th{
            padding-top: 0 !important;
            color: var(--shade-7);
            text-transform: uppercase;
            font-weight: 500;
            font-size: variables.$fontsize-s;
            border-top: none;
            border-bottom: 1px solid var(--border-gray) !important;
        }
    }
}
.panel.hasState{
    margin-top: 0 !important;
}

.hide--md{
    display: block !important;
}
.show--md{
    display: none !important;
}

.hide--sm{
    display: block !important;
}
.show--sm{
    display: none !important;
}

.hide--xs{
    display: block !important;
}
.show--xs{
    display: none !important;
}
.hide--lgx{
    display: block !important;
}
.show--lgx{
    display: none !important;
}
.hide--xs--flex{
    display: flex !important;
}
.show--xs--flex{
    display: none !important;
}

@media only screen and (max-width: 1200px) {
    .newsletter{
        .title{
            font-size: 24px !important;
        }
        .subtitle{
            font-size: 22px !important;
            line-height: 22px;
        }
    }
}

// lg x
@media only screen and (max-width: 1000px) {
    .hide--lgx{
        display: none !important;
    }
    .show--lgx{
        display: block !important;
    }
    // new index
    .new-index{
        h2{
            font-size: 18px;
            margin: 0;
            border-bottom: 1px solid #ECECEC;
            padding-bottom: 14px;
        }
        .app-tooltip-wrap{
            .app-tooltip{
                &.app-tooltip-left-nowrap{
                    right: auto !important;
                    left: 0 !important;
                    top: calc(-100% - 4px) !important;
                    transform: none;
                    border: none;
                    border-bottom: 2px solid var(--gray);
                    &::after{
                        top: calc(100% + 2px);
                        transform: none;
                        right: auto;
                        left: 10px;
                    }
                }
            }
        }
    }
}

// md
@media only screen and (min-width: 992px) {
    nav.mobile-menu{
        display: none !important;
    }
}

// md
@media only screen and (max-width: 991px) {
    // general
    .align-items-start--md{
        align-items: flex-start !important;
    }
    .hide--md{
        display: none !important;
    }
    .show--md{
        display: block !important;
    }

    // newsletter
    .newsletter{
        .newsletter-input-col{
            display: flex;
            justify-content: center;
        }
        .title{
            line-height: 1.2;
        }
        .subtitle{
            line-height: 1.2;
        }
        &.success{
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }
    }

    // header
    header .container--mx{
        display: none !important;
    }
    header .container--md{
        display: flex !important;
    }
    header{
        min-height: 55px !important;
        padding-bottom: 12px !important;
        padding-top: 12px !important;
    }

    // home
    .head--ticker{
        min-width: 160px !important;
    }

    // register activities
    .register-activities{
        .active-in-wrap{
            flex-direction: column !important;
            align-items: start !important;
            .label--sep{
                margin-top: 7px;
                margin-bottom: 7px;
                font-weight: 400;
                font-size: 20px;
            }
            &.active-in-wrap-single{
                flex-direction: row !important;
                .label--sep{
                    margin-top: 0 !important;
                    font-weight: 600;
                }
            }
        }
        .active-in-wrap.active{
            margin-bottom: 22px;
        }
    }
}

// sm
@media only screen and (max-width: 768px) {
    // exception
    .checkboxes--single{
        margin-top: 9px;
    }
    .label--sep--single{
        margin-bottom: 0 !important;
    }
    // register activities
    .register-activities{
        .active-in-wrap{
            .label--sep{
                font-size: 16px;
            }
        }
        .app-tooltip{
            display: none !important;
        }
        .field .label{
            white-space: unset;
        }
    }
    // general
    .button-link, .button{
        font-size: 18px;
        &span{
            font-size: 18px;
        }
    }
    h1{
        font-size: 24px;
        .light{
            font-size: 25px;
        }
    }
    .field{
        .label{
            font-size: 16px !important;
            margin-bottom: 5px !important;
        }
    }
    .display-label{
        font-size: 16px !important;
        margin-bottom: 5px !important;
    }
    .descr{
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .align-items-start--sm{
        align-items: flex-start !important;
    }
    .hide--sm{
        display: none !important;
    }
    .show--sm{
        display: block !important;
    }
    .container {
        padding-left: variables.$spacing-xs;
        padding-right: variables.$spacing-xs;
    }
    .page{
        overflow-x: hidden;
        padding-top: 30px !important;
    }
    .panel{
        padding-left: 16px !important;
        padding-right: 16px !important;
        margin-left: -17px !important;
        margin-right: -17px !important;
    }
    .profile .panel{
        padding-bottom: 0 !important;
    }
    .modal{
        .title{
            font-size: 24px !important;
        }
        .subtitle{
            font-size: 25px !important;
        }
    }
    .field-text{
        input{
            padding: 7px 10px !important;
        }
    }
    h2{
        font-size: 20px !important;
    }
    label.check span.label{
        font-size: 16px !important;
        transform: translateY(0) !important;
        line-height: 17px;
    }

    // home
    .home .button-wrap{
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .home{
        padding-bottom: 20px !important;
        .part{
            font-size: 24px !important;
        }
        .index{
            font-size: 18px !important;
        }
        .head--ticker{
            min-width: 130px !important;
        }
        .head--traded{
            max-width: 130px !important;
        }
        .select-table{
            margin-top: 20px !important;
        }
    }

    // footer
    .footer{
        .top{
            // padding-left: variables.$spacing-xs !important;
            // padding-right: variables.$spacing-xs !important;
            .logos{
                flex-direction: column;
                gap: 32px;
                margin-top: 0 !important;
                padding: 0 24px;
                .logo-wrap{
                    
                    span{
                        
                    }
                    img{
                        
                    }
                }
                .sep-v{
                    width: 100% !important;
                    height: 1px !important;
                }
            }
            // .title{
            //     margin-bottom: 12px;
            // }
            // .subtitle{
            //     font-size: 16px !important;
            // }
        }
        .bot{
            font-size: 16px !important;
            padding-left: variables.$spacing-xs !important;
            padding-right: variables.$spacing-xs !important;
        }
    }
    .newsletter{
        .title{
            font-size: 24px !important;
        }
        .subtitle{
            font-size: 22px !important;
        }
        .success-state{
            .title{
                font-size: 20px !important;
            }
            .subtitle{
                font-size: 18px !important;
            }
        }
        &.success{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    // register
    .panel.hasState{
        .success-state, .failure-state{
            padding-left: 65px;
            padding-top: 60px;
            padding-right: 25px;
        }
        .c{
            top: -20px;
            left: -20px;
        }
        .c-check{
            top: 18px;
            left: 18px;
            width: 55px;
            height: 55px;
        }
    }
    .success-state{
        .title{
            font-size: 26px !important;
        }
        .subtitle{
            font-size: 25px !important;;
        }
    }
    .failure-state{
        .title{
            font-size: 26px !important;;
        }
        .subtitle{
            font-size: 25px !important;;
        }
    }
    // users
    .users{
        .panel{
            margin-bottom: 80px !important;
            padding-bottom: 28px !important;
            min-height: 0px !important;
        }
    }
    
}


// sm x
@media only screen and (max-width: 600px) {
    // general
    .hide--xs{
        display: none !important;
    }
    .show--xs{
        display: block !important;
    }
    .hide--xs--flex{
        display: none !important;
    }
    .show--xs--flex{
        display: flex !important;
    }

    // home
    .home{
        .index{
            font-size: 20px !important;
        }
        .under-threshold-disclaimer{
            margin-bottom: 20px;
        }
    }

    // modals
    body .modal .modal-dialog {
        max-width: 100% !important;
        width: 100% !important;
        min-height: 100vh !important;
        margin: 0 !important;
        border-radius: 0 !important;
        .modal-content{
            border-radius: 0 !important;
            min-height: 100vh !important;
            padding: 40px 24px !important;
            padding-top: 50px !important;
        }
    }
    
}

// sm
@media only screen and (max-width: 576px) {
    // users
    .users .button-wrap{
        margin-top: 14px;
        margin-bottom: 8px;
        align-items: flex-start !important;
    }
    // new-index
    .new-index{
        .app-tooltip-wrap{
            .app-tooltip{
                // transform: translatey(-5px);
                right: 0;
                &::after{
                    right: 6px;
                }
                &.app-tooltip-left-nowrap{
                    right: auto !important;
                    left: 0 !important;
                    top: -100%;
                    transform: none;
                }
            }
        }
    }
    // general
    .button-row{
        flex-direction: column !important;
        width: 100%;
        margin-top: 32px;
        .button{
            margin-bottom: 28px;
            width: 100%;
        }
        .button-link{
            margin-left: 0 !important;
        }
    }
    // footer
    .footer{
        // .top{
        //     .logos{
        //         .logo-wrap{
        //             padding-left: 12px !important;
        //             padding-right: 12px !important;
        //             span{
        //                 font-size: 12px !important;
        //                 font-weight: 300;
        //             }
        //         }
        //     }
        // }
        .bot{
            font-size: 12px !important;
                font-weight: 300;
        }
    }
    // newsletter
    .newsletter{
        &.success{
            padding-top: 20px !important;
            padding-bottom: 20px !important;
        }
    }
}


// datepicker
// ng bootstrap datepicker

ngb-datepicker{
    box-shadow: 0 3px 12px rgb(144 144 144 / 25%) !important;
    border: none !important;
    .text-muted{
        color: #BCBCBD !important;
    }
}
.ngb-dp-navigation-select{
    .form-select{
        &:first-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: right !important;
                font-weight: 500 !important;
                color:rgb(204, 52, 99) !important;
                box-shadow: none !important;
                padding-right: 2px !important;
        }
        &:last-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: left !important;
                font-weight: 500 !important;
                box-shadow: none !important;
                color:rgb(204, 52, 99) !important;
                padding-left: 2px !important;
        }
    }
}
.ngb-dp-arrow{
    &:first-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
    &:last-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
}
.ngb-dp-header{
    background-color: transparent !important;
}
.ngb-dp-weekdays{
    background-color: transparent !important;
    border-bottom: none !important;
}
.bg-primary{
    background-color:rgb(204, 52, 99) !important;
}
.ngb-dp-weekday{
    color:rgb(204, 52, 99) !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}
.ngb-dp-navigation-chevron{
    color:rgb(204, 52, 99) !important;
}