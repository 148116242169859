$im-icon-actions: "\ea01";
$im-icon-arrow-down: "\ea02";
$im-icon-arrow-left: "\ea03";
$im-icon-arrow-right: "\ea04";
$im-icon-arrow-up: "\ea05";
$im-icon-bag: "\ea06";
$im-icon-bars: "\ea07";
$im-icon-bars-burger: "\ea08";
$im-icon-bell: "\ea09";
$im-icon-calendar: "\ea0a";
$im-icon-camera: "\ea0b";
$im-icon-check: "\ea0c";
$im-icon-chev-down: "\ea0d";
$im-icon-chev-left: "\ea0e";
$im-icon-chev-right: "\ea0f";
$im-icon-chev-up: "\ea10";
$im-icon-clipboard: "\ea11";
$im-icon-close-circle: "\ea12";
$im-icon-delivery: "\ea13";
$im-icon-dispenser: "\ea14";
$im-icon-download: "\ea15";
$im-icon-editor-align-center: "\ea16";
$im-icon-editor-align-left: "\ea17";
$im-icon-editor-align-right: "\ea18";
$im-icon-editor-bold: "\ea19";
$im-icon-editor-italic: "\ea1a";
$im-icon-editor-texteditor: "\ea1b";
$im-icon-editor-title: "\ea1c";
$im-icon-editor-ul: "\ea1d";
$im-icon-editor-ul-copy: "\ea1e";
$im-icon-editor-underline: "\ea1f";
$im-icon-equal: "\ea20";
$im-icon-flag: "\ea21";
$im-icon-history: "\ea22";
$im-icon-home: "\ea23";
$im-icon-image: "\ea24";
$im-icon-link: "\ea25";
$im-icon-location: "\ea26";
$im-icon-logout: "\ea27";
$im-icon-mail: "\ea28";
$im-icon-minus: "\ea29";
$im-icon-moon: "\ea2a";
$im-icon-paperclip: "\ea2b";
$im-icon-pencil: "\ea2c";
$im-icon-phone: "\ea2d";
$im-icon-pin: "\ea2e";
$im-icon-plus: "\ea2f";
$im-icon-portal: "\ea30";
$im-icon-preview: "\ea31";
$im-icon-print: "\ea32";
$im-icon-profile: "\ea33";
$im-icon-receipt: "\ea34";
$im-icon-receipts: "\ea35";
$im-icon-reset-lock: "\ea36";
$im-icon-search: "\ea37";
$im-icon-send: "\ea38";
$im-icon-settings-filled: "\ea39";
$im-icon-settings-outline: "\ea3a";
$im-icon-shapes: "\ea3b";
$im-icon-share: "\ea3c";
$im-icon-social-facebook: "\ea3d";
$im-icon-social-instagram: "\ea3e";
$im-icon-social-linkedin: "\ea3f";
$im-icon-social-twitter: "\ea40";
$im-icon-squares-empty: "\ea41";
$im-icon-sun: "\ea42";
$im-icon-todo: "\ea43";
$im-icon-trash: "\ea44";
$im-icon-triangle-down: "\ea45";
$im-icon-triangle-left: "\ea46";
$im-icon-triangle-right: "\ea47";
$im-icon-triangle-up: "\ea48";
$im-icon-video: "\ea49";
$im-icon-warning: "\ea4a";
$im-icon-warning-filled: "\ea4b";
$im-icon-x: "\ea4c";
$im-icon-x-light: "\ea4d";

@font-face {
  font-family: "imengine";
  src: url("../font/imengine.eot");
  src: url("../font/imengine.eot?mh88kc#iefix") format("embedded-opentype"), url("../font/imengine.woff2?mh88kc") format("woff2"), url("../font/imengine.woff?mh88kc") format("woff"), url("../font/imengine.ttf?mh88kc") format("truetype"), url("../font/imengine.svg?mh88kc#imengine") format("svg");
  font-style: normal;
  font-weight: 400;
}

%im-icon {
  display: inline-block;
  font-family: "imengine" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: -.125em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.im-icon {
  @extend %im-icon;
}

.im-icon-actions::before {
  content: "\ea01";
}
.im-icon-arrow-down::before {
  content: "\ea02";
}
.im-icon-arrow-left::before {
  content: "\ea03";
}
.im-icon-arrow-right::before {
  content: "\ea04";
}
.im-icon-arrow-up::before {
  content: "\ea05";
}
.im-icon-bag::before {
  content: "\ea06";
}
.im-icon-bars::before {
  content: "\ea07";
}
.im-icon-bars-burger::before {
  content: "\ea08";
}
.im-icon-bell::before {
  content: "\ea09";
}
.im-icon-calendar::before {
  content: "\ea0a";
}
.im-icon-camera::before {
  content: "\ea0b";
}
.im-icon-check::before {
  content: "\ea0c";
}
.im-icon-chev-down::before {
  content: "\ea0d";
}
.im-icon-chev-left::before {
  content: "\ea0e";
}
.im-icon-chev-right::before {
  content: "\ea0f";
}
.im-icon-chev-up::before {
  content: "\ea10";
}
.im-icon-clipboard::before {
  content: "\ea11";
}
.im-icon-close-circle::before {
  content: "\ea12";
}
.im-icon-delivery::before {
  content: "\ea13";
}
.im-icon-dispenser::before {
  content: "\ea14";
}
.im-icon-download::before {
  content: "\ea15";
}
.im-icon-editor-align-center::before {
  content: "\ea16";
}
.im-icon-editor-align-left::before {
  content: "\ea17";
}
.im-icon-editor-align-right::before {
  content: "\ea18";
}
.im-icon-editor-bold::before {
  content: "\ea19";
}
.im-icon-editor-italic::before {
  content: "\ea1a";
}
.im-icon-editor-texteditor::before {
  content: "\ea1b";
}
.im-icon-editor-title::before {
  content: "\ea1c";
}
.im-icon-editor-ul::before {
  content: "\ea1d";
}
.im-icon-editor-ul-copy::before {
  content: "\ea1e";
}
.im-icon-editor-underline::before {
  content: "\ea1f";
}
.im-icon-equal::before {
  content: "\ea20";
}
.im-icon-flag::before {
  content: "\ea21";
}
.im-icon-history::before {
  content: "\ea22";
}
.im-icon-home::before {
  content: "\ea23";
}
.im-icon-image::before {
  content: "\ea24";
}
.im-icon-link::before {
  content: "\ea25";
}
.im-icon-location::before {
  content: "\ea26";
}
.im-icon-logout::before {
  content: "\ea27";
}
.im-icon-mail::before {
  content: "\ea28";
}
.im-icon-minus::before {
  content: "\ea29";
}
.im-icon-moon::before {
  content: "\ea2a";
}
.im-icon-paperclip::before {
  content: "\ea2b";
}
.im-icon-pencil::before {
  content: "\ea2c";
}
.im-icon-phone::before {
  content: "\ea2d";
}
.im-icon-pin::before {
  content: "\ea2e";
}
.im-icon-plus::before {
  content: "\ea2f";
}
.im-icon-portal::before {
  content: "\ea30";
}
.im-icon-preview::before {
  content: "\ea31";
}
.im-icon-print::before {
  content: "\ea32";
}
.im-icon-profile::before {
  content: "\ea33";
}
.im-icon-receipt::before {
  content: "\ea34";
}
.im-icon-receipts::before {
  content: "\ea35";
}
.im-icon-reset-lock::before {
  content: "\ea36";
}
.im-icon-search::before {
  content: "\ea37";
}
.im-icon-send::before {
  content: "\ea38";
}
.im-icon-settings-filled::before {
  content: "\ea39";
}
.im-icon-settings-outline::before {
  content: "\ea3a";
}
.im-icon-shapes::before {
  content: "\ea3b";
}
.im-icon-share::before {
  content: "\ea3c";
}
.im-icon-social-facebook::before {
  content: "\ea3d";
}
.im-icon-social-instagram::before {
  content: "\ea3e";
}
.im-icon-social-linkedin::before {
  content: "\ea3f";
}
.im-icon-social-twitter::before {
  content: "\ea40";
}
.im-icon-squares-empty::before {
  content: "\ea41";
}
.im-icon-sun::before {
  content: "\ea42";
}
.im-icon-todo::before {
  content: "\ea43";
}
.im-icon-trash::before {
  content: "\ea44";
}
.im-icon-triangle-down::before {
  content: "\ea45";
}
.im-icon-triangle-left::before {
  content: "\ea46";
}
.im-icon-triangle-right::before {
  content: "\ea47";
}
.im-icon-triangle-up::before {
  content: "\ea48";
}
.im-icon-video::before {
  content: "\ea49";
}
.im-icon-warning::before {
  content: "\ea4a";
}
.im-icon-warning-filled::before {
  content: "\ea4b";
}
.im-icon-x::before {
  content: "\ea4c";
}
.im-icon-x-light::before {
  content: "\ea4d";
}
