@use "./variables.scss";

// im webfont
.im-icon{
    transition: .2s ease;
    font-size: variables.$fontsize-l;
    &.icon-xxl{
        font-size: 18px;
    }
    &.icon-xl{
        font-size: 16px;
    }
    &.icon-l{
        font-size: 15px;
    }
    &.icon-s{
        font-size: 9px;
    }
    &.icon-xs{
        font-size: 7px;
    }
    &.icon-xxs{
        font-size: 6px;
    }
}

// customize
.icon-close{
    color: var(--shade-5);
    font-size: 24px;
}

// action icons
.action-icon{
    width: 56px;
    height: 56px;
    background: var(--white-fixed);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid #efefef;
    .theme--dark & {
        border-color: #909090;
    }
    transition: .2s ease;
    cursor: pointer;
    i{
        font-size: 25px;
    }
}

// custom
.icon-company{
    display: inline-block;
    background: url(/assets/img/icons/icon-company.svg);
    width: 20px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}