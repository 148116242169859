.tip {
    position: relative;
}

.tip::before,
.tip::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.tip:hover::before,
.tip:focus::before,
.tip:hover::after,
.tip:focus::after {
    opacity: 1;
}

.tip::before {
    // border-style: solid;
    // border-width: 1em 0.75em 0 0.75em;
    // border-color: #3E474F transparent transparent transparent;
    // bottom: 100%;
    // content: "";
    // margin-left: -0.5em;
    // transform:  scale(.6) translateY(-90%);
} 

.tip:hover::before,
.tip:focus::before {
    // transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}

.tip::after {
    background: var(--shade-7);
    border-radius: 3px;
    bottom: 100%;
    color: var(--white);
    content: attr(data-tip);
    padding: 6px 8px;
    font-size: 14px;
    transform: translatex(-50%) translatey(-6px);
    transition: .2s ease;
    white-space: nowrap;
}
.tip-right::after{
    bottom: auto;
    left: 100%;
    transform: translatex(3px) translatey(-52%);
    top: 50%;
}

.tooltip-indicator{
    position: relative;
    &::after{
        content: "";
        border-bottom: 1px dashed var(--text-color);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 2px;
    }
}

// full tooltip
.app-tooltip-wrap{
    position: relative;
    cursor: pointer;
    &:hover .app-tooltip, &:focus .app-tooltip{
        opacity: 1;
        pointer-events: all;
    }
    .app-tooltip{
        font-weight: 400;
        opacity: 0;
        // opacity: 1;
        pointer-events: none;
        transition: .15s ease;
        position: absolute;
        z-index: 1;
        bottom: calc(100% + 6px);
        right: -40px;
        min-width: 200px;
        border-bottom: 1px solid #F39235;
        background: #fef6f0;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.3) !important;
        font-size: 12px;
        line-height: 14px;
        padding: 6px 10px;
        &::after{
            content: '';
            border-style: solid;
            border-width: 4px 4px 4px 4px;
            border-color: #F39235 transparent transparent;
            position: absolute;
            top: calc(100% + 1px);
            right: 47px;
        }
        &.app-tooltip-default-centered{
            background-color: white;
            border-color: var(--gray);
            color: var(--text-color);
            right: 50%;
            transform: translatex(50%);
            &::after{
                border-color: var(--gray) transparent transparent;                
                right: 50%;
                transform: translatex(50%);
            }
        }
        &.app-tooltip-left-nowrap{
            background-color: white;
            border-color: var(--gray);
            color: var(--text-color);
            top: 50%;
            bottom: auto;
            transform: translatey(-50%);
            border: none;
            border-right: 2px solid var(--gray);
            min-width: 0;
            white-space: nowrap;
            right: calc(100% + 7px);
            left: auto;
            &::after{
                border-color: var(--gray) transparent transparent;
                right: -10px;
                transform: translateY(-50%) rotate(
            -90deg
            );
                top: 50%;
                bottom: auto;
                left: auto;
            }
        }
    }
}